.wrapper {
  background-color: rgb(59 130 246);
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 50;
}
/* stylelint-disable-next-line selector-class-pattern */
.innerWrapper {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
/* stylelint-disable-next-line selector-class-pattern */
.flexBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;
  color: #fff;
}
@media (min-width: 640px) {
  /* stylelint-disable-next-line selector-class-pattern */
  .flexBox {
    flex-direction: row;
    text-align: left;
  }
}

.headline {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.subline {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.button {
  background-color: #fff;
  color: #3b82f6;
  border-radius: 0.25rem;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 0.5rem;
}

.button:hover {
  background-color: #619bf9;
  color: #fff;
  border: 1px solid #fff;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}
